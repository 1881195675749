<template>
    <section class="bg-light page-section" id="services">
    <div class="container">
      <div class="row">
        
        
      
        
      
    
        <div class="col-lg-12 text-center">
          <h2 class="section-heading text-uppercase">Activities & News</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6"  v-for="(obj,key) in serviceJSON" :key="key">
        <!-- <span  class="col-md-4">
          <span class="fa-stack fa-4x">
            <i  class="fas fa-circle fa-stack-2x text-primary"></i>
            <i class="fas fa-lock fa-stack-1x fa-inverse"></i>
          </span>
          <h4 class="service-heading">{{obj.serviceTitle}}</h4>
          <p class="text-muted">{{obj.serviceText}}</p>
        </span> -->
        <v-card
    class="mx-auto"
    max-width="800"
    style="padding-top:10px;"
  >
    <v-img
      class="white--text align-end"
      height="200px"
      :src="obj.serviceImg"
    >
      
    </v-img>
<v-card-title>{{obj.serviceTitle}}</v-card-title>
    <v-card-subtitle class="pb-0">{{obj.serviceText}}</v-card-subtitle>

    <v-card-text class="text--primary">
      

      <div style="color:#392F51">{{obj.serviceDate}}</div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="#392F51"
        text
        href="https://www.gulfood.com/" target="_blank"
      >
        View
      </v-btn>

      
    </v-card-actions>
  </v-card>
        </div>
      </div>
    
    </div>
    
  </section>
  
</template>
<script>
export default {
    data:()=>({
      serviceJSON:[{
        serviceTitle:"Gulf foods expo",
        serviceText:"Emmay Commodities will be participating at the 2020 Gulf food expo. Catch us at stall #302",
        serviceImg:require('../assets/img/gulffood.png'),
        serviceDate:"Date :16-20 Feb 2020"
      },{
        serviceTitle:"Gulf foods expo",
        serviceText:"Emmay Commodities will be participating at the 2020 Gulf food expo. Catch us at stall #302",
        serviceImg:require('../assets/img/gulffood.png'),
        serviceDate:"Date :16-20 Feb 2020"
      },{
        serviceTitle:"Gulf foods expo",
        serviceText:"Emmay Commodities will be participating at the 2020 Gulf food expo. Catch us at stall #302",
        serviceImg:require('../assets/img/gulffood.png'),
        serviceDate:"Date :16-20 Feb 2020"
      },],
      isActive:false
    })
}
</script>
<style scoped>

</style>