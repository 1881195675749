<template>
     <section class="page-section" id="gallery">
         
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="section-heading text-uppercase">Gallery</h2>
          
          <v-col cols="12" sm="14" offset-sm="0">
      
        
          <v-row>
            
              <v-card flat tile class="mx-auto " v-for="image in imagesJSON" :key="image"  style="padding-top:10px;">
                <v-img
                  :src="image"
                 
                  
                  max-height="200px" max-width="400px"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            
          </v-row>
        
      
    </v-col>
        </div>
      
         </div>
     </section>
</template>
<script>
export default {
    data:()=>({
        imagesJSON:[require("../assets/img/gallery/one.jpg"),require("../assets/img/gallery/two.jpg"),require("../assets/img/gallery/three.jpg"),require("../assets/img/gallery/four.jpg"),require("../assets/img/gallery/five.jpg"),require("../assets/img/gallery/six.jpg"),require("../assets/img/gallery/nine.jpg"),require("../assets/img/gallery/eight.jpg")]
    })
}
</script>
<style scoped>

</style>