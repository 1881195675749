<template>
  <div id="app">
    <v-app > 
      
    <v-app-bar  class="navbar-expand-lg" 
      
      color="rgba(255, 255, 255, 0.9)"
  app
      height="100px"      
     
      
    >
<div class="middlenav collapse navbar-collapse">
            <v-btn  href="#page-top" text>   <div>   <v-img src="./assets/emmaylogo.png"  max-height="300px" max-width="100px"></v-img></div></v-btn>

      <v-toolbar-title ><a class="navbar-brand" style="color:#392F51" href="#page-top"><span  style="font-size:14px;">Emmay Commodities<div style="font-size:12px;">Cape Town,South Africa</div></span></a></v-toolbar-title>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#e2e3e4"
          dark
          fab
          x-small
          v-bind="attrs"
          v-on="on"
                              href="https://drive.google.com/file/d/1elgXYnkCwJIDhpB638azE8Q2TNjhGpI4/view?usp=sharing" target="_blank"

        >
          <v-img src="./assets/popiact.png"  max-height="300px" max-width="50px"></v-img>
        </v-btn>
      </template>
      <span>Emmay Commodities SA (Pty) Ltd - POPI ACT</span>
    </v-tooltip>
     <v-spacer></v-spacer>
      
        
          <v-btn  style="color:#392F51;text-decoration:none;" @mouseover="aHover=true" v-if="aHover===false" text
             href="#about">About
          </v-btn>
          <v-btn  style="color:#392F51;text-decoration:none;transform: scale(1.2);" @mouseleave="aHover=false" v-if="aHover===true" text
             href="#about">About
          </v-btn>
          
          <v-btn style="color:#392F51;text-decoration:none;" @mouseover="pHover=true" v-if="pHover===false" text
             href="#portfolio">Products
          </v-btn>
          <v-btn style="color:#392F51;text-decoration:none;transform: scale(1.2);" @mouseleave="pHover=false" v-if="pHover===true" text
             href="#portfolio">Products
          </v-btn>
          <v-btn style="color:#392F51;text-decoration:none;transform: scale(1.2);" @mouseleave="fHover=false" v-if="fHover===true" text
             href="#team">Fruit Calender
          </v-btn>
          <v-btn style="color:#392F51;text-decoration:none;" @mouseover="fHover=true" v-if="fHover===false" text
             href="#team">Fruit Calender
          </v-btn>
          <v-btn style="color:#392F51;text-decoration:none;transform: scale(1.2);" @mouseleave="sHover=false" v-if="sHover===true" text
             href="#services">Activities
          </v-btn>
          <v-btn style="color:#392F51;text-decoration:none;" @mouseover="sHover=true" v-if="sHover===false" text
             href="#services">Activities
          </v-btn>
          <v-btn style="color:#392F51;text-decoration:none;" @mouseover="gHover=true" v-if="gHover===false" text
             href="#gallery">Gallery
          </v-btn>
          <v-btn style="color:#392F51;text-decoration:none;transform: scale(1.2);" @mouseleave="gHover=false" v-if="gHover===true" text
             href="#gallery">Gallery
          </v-btn>
          
          
          
          <v-btn style="color:#392F51;text-decoration:none;" v-if="cHover===false"  rounded
             href="#contact"  @mouseover="cHover=true">Contact
          </v-btn>
           <v-btn style="background-color:#392F51;color:white;text-decoration:none;" @mouseleave="cHover=false" v-if="cHover===true" rounded
             href="#contact" >Contact
          </v-btn>
          
        </div>
      
        
      
      <a  class="emmaylogoshrink navbar-toggler-right"  href="#page-top" data-toggle="collapse"><v-img src="./assets/emmaylogo.png" max-height="200px" max-width="100px"></v-img></a>
     <a  class="emmaylogoshrink navbar-toggler-right"  href="#page-top" data-toggle="collapse"><v-tooltip  >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#e2e3e4"
          dark
          fab
          x-small
          v-bind="attrs"
          v-on="on"
                              href="https://drive.google.com/file/d/1elgXYnkCwJIDhpB638azE8Q2TNjhGpI4/view?usp=sharing" target="_blank"

        >
          <v-img src="./assets/popiact.png"  max-height="300px" max-width="50px"></v-img>
        </v-btn>
      </template>
      <span>Emmay Commodities SA (Pty) Ltd - POPI ACT</span>
    </v-tooltip></a>
     <v-spacer class="emmaylogoshrink navbar-toggler-right"></v-spacer>
             <i class="fas fa-bars fa-2x emmaylogoshrink navbar-toggler-right " @click.stop="drawer=!drawer"></i>

        
       <v-navigation-drawer right color="rgba(245, 245, 245, 0.9)" app width="100%" height="auto" v-model="drawer" absolute temporary>
         
           
          
          <v-list dense  style="background-color:rgba(245, 245, 245, 0.8)">
          <v-list-item href="#page-top"  @click.stop="drawer=!drawer" 
          >
         <v-img src="./assets/emmaylogo.png" @click.stop="drawer=!drawer" max-height="200px" max-width="100px"></v-img>
          
          <v-list-item-content>
            <v-list-item-title>         <a class="navbar-brand" style="color:#392F51" href="#page-top"><span  style="font-size:14px; padding-left:10px;">Emmay Commodities<div style="font-size:12px; padding-left:10px;">Cape Town,South Africa</div></span></a>

            </v-list-item-title>
          </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          </v-list>
        
        <v-list nav dense class="shrinkMenu" >
          <v-list-item v-for="nlink in nlinks"
          :key="nlink.id"
          link :href="nlink.ref"  @click.stop="drawer=!drawer"
          >
          <v-list-item-icon>
            <i :class="nlink.icon" style="color:#392F51"></i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{nlink.title}}
            </v-list-item-title>
          </v-list-item-content>
          </v-list-item>
          </v-list>
      
  
   
         
       </v-navigation-drawer>
  

  
    </v-app-bar>
    
 
    <!-- <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
    <div class="container">
      <a class="emmaylogo js-scroll-trigger collapse navbar-collapse"  href="#page-top"><v-img src="./assets/emmaylogo.png"  max-height="800px" max-width="150px"></v-img></a> <a class="navbar-brand js-scroll-trigger collapse navbar-collapse"  href="#page-top"><span  style="font-size:20px;">Emmay Commodities<div style="font-size:16px;">Cape Town,South Africa</div></span></a>
      <a class="emmaylogoshrink navbar-toggler-right"  href="#page-top" data-toggle="collapse"><v-img src="./assets/emmaylogo.png"  max-height="200px" max-width="100px"></v-img></a>
      <button class="navbar-toggler navbar-toggler-right " type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i class="fas fa-bars"></i>
      </button>
     
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav text-uppercase ml-auto">
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#about">About</a>
          </li>
          
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#portfolio">Products</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#services">Activities</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#team">Gallery</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->
<v-sheet id="scrolling-techniques"
      class="overflow-y-auto"
      >
  <!-- Header -->

    
         <v-carousel  height="800" dark show-arrows-on-hover cycle
   >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"  
            :src="item.src" 
          ><header class="masthead">
        <div class="container">
          <div class="intro-text">
            <div class="intro-lead-in">Welcome To</div>
            <div class="intro-heading text-uppercase">Emmay Commodities SA (PTY) LTD</div>
            <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#about">Tell Me More</a>
          </div>
        </div>
      </header>
         
                    
            <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
             </v-carousel-item>
             
           </v-carousel>
    




  <!-- About -->
  <About></About>
 

  <!-- Portfolio Grid -->
  <Portfolio></Portfolio>
  <!-- Team -->
  <Team></Team>
 <!-- Services -->
  <Services></Services>
  <!-- Gallery -->
  <Gallery></Gallery>


  

  <!-- Clients -->
 

  <!-- Contact -->
  <section class="page-section" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="section-heading text-uppercase">Contact Us</h2>
          <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
         <form action="https://app.99inbound.com/api/e/K1JPmxvd" method="POST" target="_blank">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input class="form-control" id="name" type="text" name="name" placeholder="Your Name *" required="required" data-validation-required-message="Please enter your name.">
                  <p class="help-block text-danger"></p>
                </div>
                <div class="form-group">
                  <input class="form-control" id="email" type="email" name="email" placeholder="Your Email *" required="required" data-validation-required-message="Please enter your email address.">
                  <p class="help-block text-danger"></p>
                </div>
                <div class="form-group">
                  <input class="form-control" id="phone" type="tel" name="tel" placeholder="Your Phone *" required="required" data-validation-required-message="Please enter your phone number.">
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <textarea class="form-control" id="message" name="message" placeholder="Your Message *" required="required" data-validation-required-message="Please enter a message."></textarea>
                  <p class="help-block text-danger"></p>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-lg-12 text-center">
                <div id="success"></div>
                <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit">Send Message</button>
              </div>
            </div>
          </form>
                         <div class="col-lg-12 text-center "><button  class="btn btn-primary btn-xl text-uppercase emailcol"  ><i class="fas fa-envelope"></i>&nbsp; enquiries@luluafrica.com</button></div><div class="col-lg-12 text-center "> <button  class="btn btn-primary btn-xl text-uppercase emailcol"  ><i class="fas fa-phone"></i> &nbsp;  +27 21 0011266 || +27 872558320</button></div>
<div class="col-lg-12 text-center "><button  class="btn btn-primary btn-xl text-uppercase emailcol"  ><a class="location" href="https://www.google.com/maps/dir//emmay+commodities+sa+(pty)+ltd/@-33.8601441,18.4639827,11z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1dcc517777757191:0x9407a99700cefb09!2m2!1d18.7048932!2d-33.8751579" target="_blank" ><i class="fas fa-map-marker-alt"></i> &nbsp;UNIT 7, NORTH POINT INDUSTRIAL PARK, CNR KRUIS ROAD AND, Capricorn Way, Brackenfell South, Cape Town, 7560</a></button></div>
        </div>
      </div>
    </div>
    
  </section>

  <!-- Footer -->
  <footer class="footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-4">
          <span class="copyright">Copyright &copy; Emmay Commodities SA (PTY) LTD</span>
        </div>
        <div class="col-md-4">
          <ul class="list-inline social-buttons">
            <li class="list-inline-item">
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="https://www.facebook.com/pages/EMMAY-Commodities-SA-PTY-Ltd/1780210225335151" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul class="list-inline quicklinks">
            <li class="list-inline-item">
              <a href="https://www.linkedin.com/in/georgeo-thanathara-a3274b188" target="_blank">Created & Designed by Georgeo</a>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  </footer>
</v-sheet>
<template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
    </v-app>
    
  
  </div>
</template>

<script>

import Portfolio from './components/Portfolio.vue';
import About from './components/About.vue';

import Services from './components/Services.vue';
import Team from './components/Team.vue';

import Gallery from './components/Gallery.vue';

//import Clients from './components/Clients.vue';

export default {
  name: "App",
  components:{
    Portfolio,
    About,
    Services,
    Team,
    Gallery
   // Clients,
    
  },
 

  data: () => ({
   items: [
          {
            src: require('./assets/img/tet.jpg'),
          },
          {
            src: require('./assets/img/test.jpg'),
          },
          
          {
            src: require('./assets/img/tettwo.jpg'),
          },
          {
            src: require('./assets/img/testtwo.jpg'),
          },
          
        ],
        drawer:null,
        nlinks:[{
          title:"About",
          icon:"fas fa-address-card",
          ref:"#about"
        },
        {
          title:"Products",
          icon:"fas fa-truck",
          ref:"#portfolio"
        },
        {
          title:"Fruit Calender",
          icon:"far fa-calendar-alt",
          ref:"#team"
        },
        {
          title:"Activities",
          icon:"fas fa-newspaper",
          ref:"#services"
        },
        {
          title:"Gallery",
          icon:"fas fa-images",
          ref:"#gallery"
        },
        {
          title:"Contact",
          icon:"fas fa-id-card",
          ref:"#contact"
        },
        
        ],
        cHover:false,
        gHover:false,
        pHover:false,
        sHover:false,
        fHover:false,
        aHover:false,
        
        
        
      
  }),
  
};
</script>
<style scoped>
.logoemmay{
  margin-top: 20px;
 width: 150px;
 height: auto;
}

.carousel{
  background-color: black;
}
.emailcol{
  background-color: rgba(255, 255, 255, 0.8);
  color:#392F51
  
}
.location{
  text-decoration: none;
    color:#392F51

}
@media screen and (max-height:380px){

  .shrinkMenu{
    height: 180px;
    overflow-y: auto;
  }
}



</style>