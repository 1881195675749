<template>

    <section class="page-section" id="about">
      <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="section-heading text-uppercase">About us</h2>
          
  <h5 class="section-subheading text-muted">Emmay commodities SA(PTY) Ltd is a South African arm and subsidiary company of <a class="lululink" href="https://www.lulugroupinternational.com/" target="_blank">Lulu Group
International</a>, a prominent purveyor of International business portfolio that ranges from
hypermarkets, shopping malls, manufacturing and trading goods, hospitality and real estate. The
group spread over it’s presence in 22 countries across the Middle East, Asia, Europe, US and
African continent holding a lion’s share in the retail industry in the GCC with an annual turnover
of USD7.4 Billion.</h5>
<br /><br />

 
    <div class=" text-center">
    <img src='../assets/img/yusuftwo.png' alt="Forest"  class="img-fluid" style="width:60%"><h3 class="section-subheading text-muted" id="slogan">Yusuffali M A</h3> 
            <h3 class="section-subheading text-muted">Chairman & Managing Director of LuLu Group.</h3></div>
  

  
  

          

            <!-- <v-avatar size="250" class="team-member"><img  src='../assets/img/yusuf.png' alt=""></v-avatar> -->
          
          <h5 class="section-subheading text-muted">Emmay Commodities started operation in Cape Town, South Africa in 2014 with an aspiration of
procuring and exporting South African fresh produce to our retail network in the GCC.</h5>
<h5 class="section-subheading text-muted">In 2018
the group expanded it’s operation into grocery, commodities, Meat &amp; fish with a state of art
facility at Brackenfell in the Western Cape province in South Africa. The objective behind this
operation is to procure South African products directly from the manufacturer to ensure the
quality &amp; consistency in order to accelerate our logistics operations.</h5><br />
<h5 class="section-subheading text-muted">We are
focusing to provide a platform to the local brands in South Africa and associating with agricultural
and farming segments in order to showcase their products in to the GCC market through 180
plus hypermarkets. Emmay Commodities is creating economic growth by generating demand for
consumer goods and food items and employment opportunities aswell.</h5>

        </div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h3 class="section-heading text-uppercase">Timeline</h3>
        </div>
      </div>
      <div class="row" v-for="(obj,key) in timelineJSON" :key="key">
        <div class="col-lg-12">
          <ul class="timeline">
            <li>
              <div class="timeline-image">
                <img class="rounded-circle img-fluid" src="img/about/1.jpg" alt="">
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4>{{obj.year}}</h4>
                  <h4 class="subheading">{{obj.highlight}}</h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">{{obj.text}}</p>
                </div>
              </div>
            </li>
            
            
          </ul>
        </div>
      </div>
    </div> -->
  </section>
</template>
<script>
export default {
    
    data:()=>({
        timelineJSON:[{
            year:"2004-2006",
            highlight:"started business",
            text:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!"

        },{
            year:"2004-2006",
            highlight:"started business",
            text:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ut voluptatum eius sapiente, totam reiciendis temporibus qui quibusdam, recusandae sit vero unde, sed, incidunt et ea quo dolore laudantium consectetur!"

        }]
    })
}
</script>
<style scoped>
#slogan{
  margin-bottom: 0px;
}
.lululink{
  text-decoration: none;
  
}
</style>