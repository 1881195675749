import { render, staticRenderFns } from "./Portfolio.vue?vue&type=template&id=66fc3f67&scoped=true&"
import script from "./Portfolio.vue?vue&type=script&lang=js&"
export * from "./Portfolio.vue?vue&type=script&lang=js&"
import style0 from "./Portfolio.vue?vue&type=style&index=0&id=66fc3f67&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66fc3f67",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VDialog,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VList,VListItem,VListItemContent,VListItemIcon,VOverlay,VProgressCircular,VSpacer})
