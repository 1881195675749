<template>
  <section class="bg-light page-section" id="portfolio">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="section-heading text-uppercase">Our Products</h2>
          
        </div>
      </div>
      <div class="row text-center">
        <div
          class="col-md-4 col-sm-6 portfolio-item"
          v-for="(obj, key) in portfolioJSON"
          :key="key"
        >
          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal2">
            <img class="img-fluid" :src="obj.img" />
          </a>
          <div class="portfolio-caption">
            <h4>{{ obj.caption }}</h4>
            <p class="text-muted">{{ obj.title }}</p>
            <br />
            <v-dialog v-model="dialog" max-width="1000" :retain-focus="false">
              <template v-slot:activator="{ on }">
                <v-btn
                  class="ma-2"
                  outlined
                  color="#392F51"
                  light
                  v-on="on"
                  @mousedown="handleClick"
                        @click="overlay = !overlay"

                  :id="key"
                  >see more</v-btn
                >
              </template>
              <div class="text-center">
    

    
  </div>
              <v-card class="startlower">
                <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
                <div class="container testing">
                  <div class="row text-center" v-if="displayfmcg === true">
                    <div class="col-lg-12 text-center">
                      <h2 class="section-heading text-uppercase">FMCG</h2>
                      <h5 class="text-muted">
                        Emmay Commodities is associating directly with leading brands from South Africa and exporting fresh and quality products to GCC. This allow us to provide competitive and professional services to both our customer and suppliers
                      </h5>
                    </div>
                    <div
                      class="col-md-3 col-sm-6"
                      v-for="(obj, key) in fmcgclientJSON"
                      :key="key"
                    >
                      <a>
                        <img
                          class="img-fluid d-block mx-auto"
                          :src="obj.clientImg"
                          :alt="obj.clientName"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="container" v-else>
                    <div class="row text-center">
                      <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">
                          {{ dialogInf.caption }}
                        </h2>
                        <h5 class="text-muted">
                          {{ dialogInf.title }}
                        </h5>
                      </div>
                      <v-expansion-panels hover>
                        <v-expansion-panel
                          v-for="(item, i) in innerProducts"
                          :key="i"
                        >
                          <v-expansion-panel-header>
                            <span>
                              <v-avatar size="36px">
                                <img
                                  v-if="item.avatar"
                                  alt="Avatar"
                                  :src="item.avatar"
                                />
                              </v-avatar>
                            </span>
                            <span> {{ item.caption }}</span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list elevation="10px" rounded>
                              <v-list-item
                                v-for="(innerproductitem, j) in item.innerItems"
                                :key="j"
                              >
                                <v-list-item-icon
                                  ><i class="far fa-circle"></i
                                ></v-list-item-icon>
                                <v-list-item-content
                                  v-text="innerproductitem.title"
                                ></v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </div>
                </div>
                <v-card-actions class="back-button">
                  <v-spacer></v-spacer>
                  <v-btn style="color:#392F51" @click="dialog = false"
                    >Back</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    overlay: false,
    portfolioJSON: [
      {
        id: "0",
        img: require("../assets/img/galatwo.jpg"),
        caption: "FMCG",
        title:
          "Emmay Commodities is associating directly with leading brands from South Africa and exporting fresh and quality products to GCC. This allow us to provide competitive and professional services to both our customer and suppliers",
        products: ["fmcg"]
      },
      {
        id: "1",
        img: require("../assets/img/fresh.jpg"),
        caption: "Fresh Produce",
        title:
          "African and Latin American fresh produces are inevitable to the Middle East market and we facilitate the constant supply to satisfy the needs of our customers.",
        products: [
          {
            id: "1",
            caption: "Pear",
            avatar: require("../assets/img/pears.jpg"),
            innerItems: [
              {
                active: true,
                title: "Pachkam"
              },
              {
                active: true,
                title: "Rose Marie"
              },
              {
                active: true,
                title: "Sempre"
              },
              {
                active: true,
                title: "Forelle"
              },
              {
                active: true,
                title: "Vermont"
              }
            ]
          },
          {
            id: "0",
            caption: "Citrus",
            avatar: require("../assets/img/citrus.jpg"),
            innerItems: [
              {
                active: true,
                title: "Valencia"
              },
              {
                active: true,
                title: "Navel"
              },
              {
                active: true,
                title: "Lemon"
              },
              {
                active: true,
                title: "Satsuma"
              },
              {
                active: true,
                title: "Nova"
              },
              {
                active: true,
                title: "Clementine"
              },
              {
                active: true,
                title: "African Sunset"
              },
              {
                active: true,
                title: "Morr"
              },
              {
                active: true,
                title: "Minniola"
              },
              {
                active: true,
                title: "Scarlet"
              },
              {
                active: true,
                title: "Nadarcott"
              }
            ]
          },

          {
            id: "2",
            caption: "Berries & Grapes",
            avatar: require("../assets/img/grapes.jpg"),
            innerItems: [
              {
                active: true,
                title: "White Grape"
              },
              {
                active: true,
                title: "Black Grape"
              },
              {
                active: true,
                title: "Red Grape"
              },
              {
                active: true,
                title: "Red Globe Grape"
              },
              {
                active: true,
                title: "Blueberries"
              },
              {
                active: true,
                title: "Strawberries"
              }
            ]
          },
          {
            id: "3",
            caption: "Stone Fruit",
            avatar: require("../assets/img/stone.jpg"),
            innerItems: [
              {
                active: true,
                title: "Nectarine"
              },
              {
                active: true,
                title: "Red Plum"
              },
              {
                active: true,
                title: "Black Plum"
              },
              {
                active: true,
                title: "Angelino Plums"
              },
              {
                active: true,
                title: "Persimmon"
              }
            ]
          },
          {
            id: "4",
            caption: "Tropical Fruits",
            avatar: require("../assets/img/tropical.jpg"),
            innerItems: [
              {
                active: true,
                title: "Pachkam Pear"
              },
              {
                active: true,
                title: "Rose Marie Pear"
              },
              {
                active: true,
                title: "Sempre Pear"
              },
              {
                active: true,
                title: "Forelle Pear"
              },
              {
                active: true,
                title: "Vermont Pears"
              },
              {
                active: true,
                title: "Royal Gala Apple"
              },
              {
                active: true,
                title: "Granny Smith Apple"
              },
              {
                active: true,
                title: "Pink Lady Apple"
              },
              {
                active: true,
                title: "Golden Apple"
              },
              {
                active: true,
                title: "Top Red Apple"
              },
              {
                active: true,
                title: "Pomegranite"
              }
            ]
          }
        ]
      },
      {
        id: "2",
        img: require("../assets/img/meat.jpg"),
        caption: "Meat & Fish",
        title:
          "We are privileged to introduce Ostrich Meat, Lamb, Mutton, Beef and various fish specious from South Africa to the customers in Middle East through our retail brand LULU",
        products: [
          {
            id: "0",
            caption: "Meat",
            avatar: require("../assets/img/meata.jpg"),
            innerItems: [
              {
                active: true,
                title: "Beef"
              },
              {
                active: true,
                title: "Mutton"
              },
              {
                active: true,
                title: "Lamb"
              },
              {
                active: true,
                title: "Ostrich"
              }
            ]
          },
          {
            id: "1",
            caption: "FIsh",
            avatar: require("../assets/img/fish.jpg"),
            innerItems: [
              {
                active: true,
                title: "Yellow tail"
              },
              {
                active: true,
                title: "Hake"
              },
              {
                active: true,
                title: "Yellow Fin Tuna"
              }
            ]
          }
        ]
      },
      {
        id: "3",
        img: require("../assets/img/comm.jpg"),
        caption: "Commodities",
        title:
          "Commodities export consists of bulk trade of dried fruits and various nuts.",

        products: [
          {
            id: "0",
            caption: "Dried Fruit",
            avatar: require("../assets/img/driedfruits.jpg"),
            innerItems: [
              {
                active: true,
                title: "Delicious and highly flexible. Dried fruits are an excellent nutritional booster. We source high-quality selection of raisins from the bank of the great orange river, dried mango, peaches, pineapples, prunes, etc from all 9 provinces across south Africa. ",
              }
            ]
          },
          {
            id: "1",
            caption: "Nuts and Peanuts",
            avatar: require("../assets/img/nuts.jpg"),
            innerItems: [
              {
                active: true,
                title: "Nuts are high in proteins, minerals & essential oils. It is also easy to incorporate in a balance diet.  We source the best tree nuts (macadamia, pecan nut, etc) & peanuts from south africa",
              }
            ]
          },
          {
            id: "2",
            caption: "Charcoal",
            avatar: require("../assets/img/charcoal.jpg"),
            innerItems: [
              {
                active: true,
                title: "We are partnering with the leading charcoal company in south africa, nam-char, for lulu private label  in the gcc. In addition to this we are exporting restaurant charcoal for our in house purpose.",
              }
            ]
          }
        ]
      }
    ],
    fmcgclientJSON: [
      {
        clientName: "Bakers Biscuit",
        clientImg: require("../assets/img/logo/bakers.png")
      },
      {
        clientName: "Cape Herbs and Spices",
        clientImg: require("../assets/img/logo/chas.png")
      },
      {
        clientName: "Cape Cookies",
        clientImg: require("../assets/img/logo/capecookies.png")
      },
      {
        clientName: "Tiger Brands",
        clientImg: require("../assets/img/logo/tigerbrands.png")
      },
      {
        clientName: "Cape CHope Soy Milk",
        clientImg: require("../assets/img/logo/chsm.png")
      },
      {
        clientName: "Blue Diamond",
        clientImg: require("../assets/img/logo/bluediamond.png")
      },
      {
        clientName: "Dewlands",
        clientImg: require("../assets/img/logo/dewlands.png")
      },
      {
        clientName: "Carpe Diem",
        clientImg: require("../assets/img/logo/cd.png")
      },
      {
        clientName: "Willow Creek",
        clientImg: require("../assets/img/logo/willowcreek.png")
      }
    ],
    dialog: false,
    displayfmcg: false,
    dialogInf: {
      id: 1,
      img: require("../assets/img/fresh.jpg"),
      caption: "Fresh Produce",
      title:
        "orem ipsum dolor sit amet, consectetur adipisicing elit. Minima maxime quam architecto quo inventore harum ex magni, dicta impedit.",
      products: ["fresh"]
    },
    innerProducts: [
      {
        id: "0",
        caption: "FMCG"
      }
    ]
  }),
  watch: {
      overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 1000)
      },
    },
  methods: {
    handleClick(ide) {
      this.displayfmcg = false;
      // console.log(ide.toElement.id,ide.srcElement.parentNode.id)
      if (ide.toElement.id === "" && ide.srcElement.parentNode.id === "") {
        alert("error");
      } else {
        if (ide.toElement.id === "") {
          for (let i = 0; i < this.portfolioJSON.length; i++) {
            if (ide.srcElement.parentNode.id === this.portfolioJSON[i].id) {
              if (ide.srcElement.parentNode.id === "0") {
                this.displayfmcg = true;
                console.log(this.displayfmcg);
              } else {
                this.dialogInf = this.portfolioJSON[i];
                this.innerProducts = this.portfolioJSON[i].products;
              }

              console.log("hehehheh");
            }
          }
        } else if (ide.srcElement.parentNode.id === "") {
          for (let i = 0; i < this.portfolioJSON.length; i++) {
            if (ide.toElement.id === this.portfolioJSON[i].id) {
              if (ide.toElement.id === "0") {
                this.displayfmcg = true;
                console.log(this.displayfmcg);
              } else {
                this.dialogInf = this.portfolioJSON[i];
                this.innerProducts = this.portfolioJSON[i].products;
              }

              console.log("hehehheh");
            }
          }
        } else {
          console.log("error");
        }
      }
    }
  }
};
</script>
<style scoped>
#arrowdown {
  text-align: right;
}
</style>
