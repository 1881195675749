import 'babel-polyfill'
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import './assets/css/styles.css';
import './assets/css/bootstrap.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
Vue.config.productionTip = false;
Vue.use(vuetify, {
  iconfont: 'mdi'
})
new Vue({
  vuetify,

  render: function(h) {
    return h(App);
  }
}).$mount("#app");
