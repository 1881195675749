<template>
  <section class="page-section" id="team">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 class="section-heading text-uppercase">Fruit Calender</h2>
          
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-sm-4" v-for="(obj,key) in teamJSON" :key="key">
          <div class="team-member">
            <img class="mx-auto rounded-circle" :src="obj.teamImg" alt="">
            <h4>{{obj.teamName}}</h4>
            <p class="text-muted">{{obj.teamJob}}</p>
            <ul class="list-inline social-buttons">
              <li class="list-inline-item">
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="#">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="#">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
  
      <v-expansion-panels hover>
        <v-expansion-panel v-for="(country, key) in countries" :key="key">
          
          <v-expansion-panel-header  >
            <v-img :src="country.icon" max-height="40" max-width="40"><template v-slot:placeholder >
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center" 
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template></v-img>
            <h4
              style="padding-left:10px;"
              class="section-heading text-uppercase"
            >
              {{ country.title }}
            </h4>
          </v-expansion-panel-header>
          
          <v-expansion-panel-content>
            <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
            <v-data-table
              :headers="headers"
              :items="country.desserts"
              item-key="name"
              :items-per-page="5"
              :search="search"
              

            >
              <template v-slot:item.jan="{ item }">
                <i v-if="item.jan===true" style="color:#228B22" class="fas fa-check"></i>
              </template>
              <template v-slot:item.feb="{ item }">
                 <i v-if="item.feb===true" style="color:#228B22" class="fas fa-check"></i>
              </template>
              <template v-slot:item.march="{ item }">
                 <i v-if="item.march===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
              <template v-slot:item.april="{ item }">
                 <i v-if="item.april===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
              <template v-slot:item.may="{ item }">
                 <i v-if="item.may===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
              <template v-slot:item.june="{ item }">
                 <i v-if="item.june===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
              <template v-slot:item.july="{ item }">
                 <i v-if="item.july===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
              <template v-slot:item.agst="{ item }">
                 <i v-if="item.agst===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
              <template v-slot:item.sept="{ item }">
                 <i v-if="item.sept===true" class="fas fa-check" style="color:#228B22"></i>
                
              </template>

              <template v-slot:item.oct="{ item }">
                 <i v-if="item.oct===true" class="fas fa-check"  style="color:#228B22"></i>
              </template>
              <template v-slot:item.nov="{ item }">
                 <i v-if="item.nov===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
              <template v-slot:item.dec="{ item }">
                 <i v-if="item.dec===true" class="fas fa-check" style="color:#228B22"></i>
              </template>
            </v-data-table>
            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    teamJSON: [
      {
        teamImg: require("../assets/img/thomas.jpg"),
        teamName: "Thomas Jefferson",
        teamJob: "Software Analyst"
      }
    ],
     search: '',

    selected2: [],
    headers: [
      { text: " ", value: "name", width: "10px", fixed: true },
      { text: "Jan", value: "jan", width: "10px", fixed: true },
      { text: "Feb", value: "feb", width: "10px", fixed: true },
      { text: "Mar", value: "march", width: "10px", fixed: true },
      { text: "Apr", value: "april", width: "10px", fixed: true },
      { text: "May", value: "may", width: "10px", fixed: true },
      { text: "Jun", value: "june", width: "10px", fixed: true },
      { text: "Jul", value: "july", width: "10px", fixed: true },
      { text: "Aug", value: "agst", width: "10px", fixed: true },
      { text: "Sep", value: "sept", width: "10px", fixed: true },
      { text: "Oct", value: "oct", width: "10px", fixed: true },
      { text: "Nov", value: "nov", width: "10px", fixed: true },
      { text: "Dec", value: "dec", width: "10px", fixed: true }
    ],
    countries: [{
        title: "South Africa",
        icon: require("../assets/img/sa.png"),
        desserts: [
          {
            value: false,
            name: "Navel",
            category: "Beverage",
            jan: false,

            feb: false,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept:true,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Valencia",
            category: "Snack",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: true,
            nov: true,

            dec: false
          },
          {
            value: false,
            name: "Lemon",
            category: "Breakfast",
            jan: false,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: true,
            nov: true,

            dec: false
          },
          {
            value: false,
            name: "Satsuma",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: true,
            may: true,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Nova",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: true,
            may: true,
            june: true,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Clementine",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: true,
            june: true,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "African Sunset",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: true,
            july: true,
            agst: true,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Morr",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: true,
            july: true,
            agst: true,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Minniola",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Scarlet",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: true,
            july: true,
            agst: true,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Nadarcott",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: true,
            sept: true,
            oct: true,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "White Grapes",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Black Grapes",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Red Globe Grapes",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Nectarines",
            category: "Beverage",
            jan: true,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: true,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Red Plums",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Black Plums",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Angelino Plums",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Potatoes",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: true,
            nov: true,

            dec: true
          },
          
          
          {
            value: false,
            name: "Persimmions",
            category: "Beverage",
            jan: false,
            feb: false,
            march: true,
            april: true,
            may: true,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct:false,
            nov: false,

            dec: false
          },
           {
            value: false,
            name: "Pachkam Pears",
            category: "Beverage",
            jan: true,
            feb: true,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: true
          },
          {
            value: false,
            name: "Rose Marie Pears",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Sempre Pears",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Forelle Pears",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Vermont Pears",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Royal Gala Apple",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june:true,
            july: true,
            agst: true,
            sept: true,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Granny Smith Apple",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june:true,
            july: true,
            agst: true,
            sept: true,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Golden Apple",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june:true,
            july: true,
            agst: true,
            sept: true,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Top Red Apples",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june:true,
            july: true,
            agst: true,
            sept: true,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Pink Lady Apple",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: true,
            june: true,
            july: true,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Pomegranite",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: true,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Blueberries",
            category: "Beverage",
            jan: true,
            feb: true,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: true,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Strawberries",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: true,
            oct: true,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Baby Vegetables",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: true,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Pine",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: true,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Papaya",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: true,
            nov: true,

            dec: true
          },
          {
            value: false,
            name: "Tommy Atkins",
            category: "Beverage",
            jan: true,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: true
          },
          {
            value: false,
            name: "Kent Mango",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
          {
            value: false,
            name: "Avocado Hasss",
            category: "Beverage",
            jan: false,
            feb: false,
            march: false,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: false,
            nov: false,

            dec: false
          }
          


          
        ]
      },
      
      {
        title: "Chile",
        icon: require("../assets/img/chile.png"),
        desserts: [
          {
            value: false,
            name: "Red Apple",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },{
            value: false,
            name: "Green Apple",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },{
            value: false,
            name: "Angelino Plums",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: true,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          },
        ]
      },
      {
        title: "Madagascar",
        icon: require("../assets/img/madagascar.png"),
        desserts: [
          {
            value: false,
            name: "Lychee",
            category: "Beverage",
            jan: true,
            feb: false,
            march: false,
            april: false,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: true
          }
        ]
      },
      {
        title: "Peru",
        icon: require("../assets/img/peru.png"),
        desserts: [
          {
            value: false,
            name: "Pomegranite",
            category: "Beverage",
            jan: false,
            feb: true,
            march: true,
            april: true,
            may: false,
            june: false,
            july: false,
            agst: false,
            sept: false,
            oct: false,
            nov: false,

            dec: false
          }
        ]
      },{
        title: "Ghana",
        icon: require("../assets/img/ghana.png"),
        desserts: [
          {
            value: false,
            name: "Fresh Yam",
            category: "Beverage",
            jan: true,
            feb: true,
            march: true,
            april: true,
            may: true,
            june: true,
            july: true,
            agst: true,
            sept: true,
            oct: true,
            nov: true,

            dec: true
          }
        ]
      },
      
    ]
  }),
  computed: {
    headers0() {
      return this.headers.map(header =>
        Object.assign({}, header, { fixed: false })
      );
    },
    headers2() {
      return [
        { text: "", fixed: true, width: "100px", sortable: false }
      ].concat(this.headers);
    },
    headers3() {
      return [{ text: "", fixed: true, width: "100px" }].concat(this.headers);
    },
    headers5() {
      return this.headers3;
    }
  },
  methods: {
    getColor(fruit) {
      if (fruit === "apple") {
        return "red";
      } else if (fruit === "lemon") {
        return "yellow";
      } else if (fruit === "orange") {
        return "orange";
      } else if (fruit === "grape") {
        return "purple";
      } else {
        return "blue";
      }
    }
  }
};
</script>
<style scoped>
#arrowdown {
  text-align: right;
}
</style>
